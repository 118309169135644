@import '../skin/theme/colors.css';

$inactive-opacity: 0.5;
$transition-time: 0.17s;

.messages-list {
  flex: 1;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &.active {
    .messages-list-item {
      header,
      .message {
        opacity: $inactive-opacity;
      }

      &.open,
      &:not(.read) {
        header,
        .message {
          opacity: 1;
        }
      }
    }
  }
}

.messages-list .pagination {
  text-align: center;
}

.messages-list-item {
  background-color: var(--dark-gray);
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color $transition-time linear;

  &:not(.open)&:hover {
    background-color: var(--dark-gray);

    .message-wrapper::after {
      background: linear-gradient(transparent, var(--dark-gray));
    }
  }

  &.open {
    cursor: default;
    padding-bottom: 15px;
  }

  &.read {
    .title::after {
      display: none;
    }

    header,
    .message {
      opacity: $inactive-opacity;
    }
  }
}

.messages-list-item header,
.messages-list-item .message {
  transition: opacity $transition-time ease;
  opacity: 1;

  img {
    max-width: 100%;
  }

  p {
    margin: 0;
    margin-bottom: 12px;
  }
}

.messages-list-item header {
  padding: 15px 10px 10px;
  cursor: pointer;

  .header-row {
    display: flex;
    align-items: center;
  }

  .title {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    padding-right: 28px;
    word-break: break-word;

    &::after {
      content: '';
      position: absolute;
      height: 13px;
      width: 13px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--primary-color);
      border-radius: 50%;
    }
  }

  .icon {
    width: 25px;
    margin-right: 15px;
  }

  .time {
    color: var(--gray);
    font-size: 10px;
    margin-left: 40px;
    padding-top: 2px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .caret {
    margin-left: auto;
    font-size: 24px;
    transform-origin: center;
    transition: transform 0.1s linear;
    transform: translateY(-1px);
    &.flipIcon {
      transform: translateY(-1px) rotate(180deg);
    }
  }
}

.messages-list-empty-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  p,
  i {
    opacity: 0.7;
  }

  p {
    margin-top: 8px;
  }

  i {
    font-size: 60px;
  }
}

.messages-list-item {
  .message {
    min-height: 50px;
    word-break: break-word;

    img {
      height: auto;
      margin-bottom: 12px;
    }

    > :first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .message-wrapper {
    position: relative;
    padding: 0 25px 0 50px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 30px;
      z-index: 2;
      width: 100%;
      background: linear-gradient(
        180deg,
        transparent,
        var(--primary-color) 80%,
        var(--primary-color) 100%
      );
      bottom: 0px;
      left: 0;
      transition: background-color 0.3s linear;
    }
  }

  &.open .message-wrapper::before {
    display: none;
  }

  .button {
    @media (max-width: 600px) {
      width: 100%;
      text-align: center;
    }
  }
}
