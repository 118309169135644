.OffCanvas {
  .canvas-content {
    background: url('../images/sidebar-pattern.png') var(--secondary-color);
    background-size: cover;

    .canvas-header {
      .back-sidebar-wrapper {
        position: absolute;
        left: 16px;
        top: 30px;
      }

      .exit-sidebar-wrapper {
        position: absolute;
        right: 16px;
        top: 30px;
      }

      .exit-sidebar-wrapper,
      .back-sidebar-wrapper {
        img {
          height: 34px;
          background: var(--primary-color);
          border-radius: 20%;
          padding: 10px;
          box-shadow: var(--button-shadow);
        }
      }

      .canvas-header-content {
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translatex(-50%);

        img {
          height: 90px;
          margin-left: -20px;
        }
      }
    }

    form {
      .tg-phonenumber-input,
      .tg-password-input,
      .tg-input,
      .tg-checkbox,
      .tg-select {
        margin-bottom: 25px;
      }
    }

    .section {
      &.membership-buttons {
        padding: 20px;

        a {
          text-align: center;
          width: 100%;
          margin-top: 20px;
        }
      }

      &.name {
        display: none;
      }

      &.initials {
        display: none;
      }

      &.balance {
        padding-bottom: 0;
        order: 2;
      }

      &.payment-buttons {
        padding-bottom: 0;
        margin: 20px 0;
        order: 3;

        &:empty {
          display: none;
        }
      }

      &.products {
        margin: 20px 0;
        order: 4;
      }

      &.profile-links {
        order: 5;
      }

      &.support {
        order: 90;
      }

      &.footer {
        justify-content: center;
        padding: 0;
        margin: 20px 0 0 0;
        order: 100;

        @media (max-width: 992px) {
          display: grid;
          margin: 20px 0 80px 0;
        }

        .button.tertiary {
          margin-top: 30px;
        }
      }
    }

    .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0']):after {
      top: 5px;
      right: 0;
      color: var(--white);
    }
  }

  &.BonusContainer {
    .AccordionItem:last-child {
      display: none;
    }

    .canvas-content {
      .container {
        a {
          &:last-child {
            margin-top: 50px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}