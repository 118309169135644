.swish-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

  .swish-wrapper #swishIframe {
    width: 0;
    height: 0;
    border: 0;
    border: none;
  }

  .swish-wrapper .swish-loader {
    padding-top: 50px;
  }

  .swish-wrapper .logo {
    height: 200px;
  }

  .swish-wrapper .info {
    padding-top: 40px;
  }
