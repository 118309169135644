.Alert {
  &.level-danger {
    background: rgba(var(--white), 0.9);
    color: var(--error);
  }

  &.level-warning {
    border: none;
    background: rgba(var(--white), 0.9);
    color: var(--black);
  }
}
