.Promotion,
.Tournament {
  color: #fff;
  box-shadow: var(--button-shadow);
  border-radius: 3px;

  .tournament-content-wrapper {
    .button {
      margin-top: 15px;
    }
  }
}
.PromotionDetailContainer,
.TournamentDetailContainer {
  .promotion-banner,
  .tournament-banner {
    .banner-content {
      color: #363636;
      background: transparent;
    }

    .banner-image {
      background-position: center !important;
    }
  }
}

.TournamentDetailContainer {
  .leaderboard-header {
    margin-bottom: 20px;
    padding: 20px;
  }
  .leaderboard {
    color: #fff;
    box-shadow: var(--button-shadow);
    border-radius: 3px;
  }
}
