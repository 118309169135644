.swish-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;

  #swishIframe {
    width: 0;
    height: 0;
    border: 0;
    border: none;
  }

  .swish-loader {
    padding-top: 50px;
  }

  .logo {
    height: 200px;
  }

  .info {
    padding-top: 40px;
  }
}
