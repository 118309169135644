.CasinoContainer {
  .main-content-container {
    margin: 0 auto;
    margin-top: calc(var(--header-height) + 5px);

    @media (max-width: 992px) {
      margin-top: calc(var(--header-height-mobile) + 5px);
    }

    .header-and-icon > span {
      font-weight: 400;
      font-size: 21px;
    }

    .GridContainer {
      max-width: 1800px;
      margin: 0 auto;
      padding: 0 20px;

      .grid-header {
        max-width: 1800px;
        padding: 0;
        margin: 0 auto 1em auto;
      }

      .GameGrid {
        max-width: 1800px;
      }

      @media (max-width: 992px) {
        padding: 0 10px;
      }
    }
  }

  .CasinoCategoryMenu {
    max-width: 1800px;
  }

  .GameGridList {
    .GameGrid {
      .grid-header {
        justify-content: space-between;
        margin: 1em 20px;
        max-width: unset;

        a {
          margin: 0;
          text-decoration: none;
        }
      }
      .slider {
        .list {
          .arrow {
            background: rgb(255 88 80 / 90%);
            color: var(--white);

            &--prev {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            &--next {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }

          .track {
            .slide-item {
              .last-slide {
                a {
                  background: var(--secondary-color);
                  color: var(--white);
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .CasinoGrid {
    margin: 20px 33px;
    padding: 0 20px;

    &:first-child {
      margin-top: 0;
    }

    &__header {
      margin-bottom: 10px;
      a {
        text-decoration: none;
      }
    }

    @media (max-width: 992px) {
      padding: 0 10px;
      margin: 0;
    }
  }

  .casino-promotion {
    width: 100%;

    .casino-promotion-bg {
      opacity: 0.2;
    }

    .content-image-wrapper {
      .casino-promotion-image {
        border-radius: 10px;
      }
    }

    .casino-promotion-video {
      video {
        height: 100%;
        width: auto;
      }

      @media (min-width: 992px) {
        video {
          max-width: 700px;
          margin-right: 20px;
        }
      }

      @media (max-width: 992px) {
        margin: 12px auto;
        video {
          max-width: 100%;
        }
      }
    }

    @media (max-width: 992px) {
      .casino-promotion-content {
        .summary {
          margin: 12px auto;
        }
        text-align: center;

        .button {
          margin: 0 auto;
        }
      }
    }
  }
}

.CasinoContainer,
.PlayContainer {
  .CasinoCategoryMenu {
    margin: 0 auto;
    padding: 0 20px;
    align-items: center;
    height: 70px;

    &::before {
      background: linear-gradient(to right, rgba(#c9edf3, 0), rgba(#c9edf3, 1));
      z-index: 10;
    }

    .category-item {
      color: var(--primary-text-color);
      font-size: 0.85rem;
      line-height: 2;

      &.active {
        color: var(--white);
        background: var(--secondary-color);
        font-weight: normal;
        border-radius: 6px;
      }

      &:hover {
        &.active {
          opacity: 1;
        }
      }
    }

    .casino-search {
      i {
        background: var(--primary-color);
        border-radius: 6px;
        color: var(--white);
        font-size: 20px;
        width: 35px;
        height: 35px;
      }

      .search-input input {
        margin-left: 0;
      }

      .tg-input {
        margin-left: 16px;
      }

      @media (max-width: 500px) {
        .search-input {
          width: 80vw;
        }
      }
    }

    @media (max-width: 992px) {
      padding: 0 10px;
    }
  }
}
