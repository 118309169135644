.tg-input.has-label {
  margin: 20px 0 0;
}

.tg-input,
.tg-checkbox,
.tg-select,
.tg-input.search-input,
.layout-item,
.tg-birthdate-input,
.responsiblegaming-signup .custom-amount .custom-amount-input.tg-input,
.responsiblegaming-limit-selector .custom-amount .custom-amount-input.tg-input,
.cashier-accordion .payment-method-selector .payment-method-item-content,
.calling-code,
.tg-phonenumber-input,
.tg-password-input,
.account-selection {
  position: relative;

  &.error {
    color: var(--white);
  }

  .tg-input__status-text,
  .tg-password-input__status-text,
  .tg-birthdate-input__status-text,
  .tg-select__status-text,
  .tg-checkbox__status-text,
  .tg-phonenumber-input__status-text {
    position: absolute;
    top: -32px;
    right: 5px;
    display: inline-block;
    margin-top: 8px;
    background: rgba(var(--dark-gray), 0.9);
    padding: 5px 8px;
    border: 1px solid var(--black);
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    color: rgb(255, 132, 132);

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 10px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--dark-gray);
    }

    &:empty {
      display: none;
    }
  }

  .Input,
  .Select {
    overflow: visible;
    color: var(--primary-text-color);

    input,
    select {
      background: var(--white);
      border-radius: 2px;
      border: none;
      box-shadow: var(--input-shadow);
      text-align: left;

      &:disabled {
        background: rgb(211, 211, 211);
      }
    }

    .Input__status-line,
    .Select__status-line {
      display: none;
    }
  }

  .select-icon {
    top: 34px;
  }
}

.tg-checkbox {
  margin-bottom: 10px;
}

.tg-select,
.tg-input {
  margin-bottom: 20px;
}

input[type='radio']:checked {
  background: var(--secondary-color);
  border-color: var(--primary-color);
  border-width: 2px;
}

.tg-phonenumber-input {
  position: relative;
  .Select {
    width: 33.33% !important;
    padding-right: 5px;
    margin-right: 13px;
  }

  .Input {
    width: 66.66% !important;
  }

  .tg-phonenumber-input__status-text,
  .tg-phonenumber-input__helper-text {
    width: initial;
  }
}

.tg-input {
  &.layout-item-6:first-child,
  &.layout-item-4:first-child {
    padding-right: 5px;
  }

  &.layout-item-4:nth-child(2) {
    padding-right: 10px;
  }

  &.layout-item-6:last-child,
  &.layout-item-8:last-child {
    padding-left: 5px;
  }

  &.layout-item-4:nth-child(4) {
    padding-left: 10px;
  }
}

.tg-select {
  &.layout-item-6:first-child {
    padding-right: 5px;
  }
}

.tg-input,
.passwordContainer {
  .icon.icon-eye,
  .icon.icon-eye-slash,
  .toggle-pasword {
    color: var(--primary-text-color);
    top: 28px;
  }
}

.phone-spinner {
  position: absolute;
  top: 25px;
}
