.AccordionWidget {
  color: var(--white);
  .AccordionItem {
    &__header {
      background-color: var(--accordion);
      &:hover {
        background-color: var(--accordion);
      }
      &__expand-icon {
        color: var(--white);
      }
      .info-amount {
        color: var(--gray);
      }
      &__tooltop {
        border-radius: 10px;
      }
    }
    &__content {
      > div {
        padding: 8px 15px 20px;
      }
      background-color: var(--accordion);
      .table {
        background: var(--accordion);
      }
      .pagination {
        display: flex;
      }
    }
    &:nth-child(2) {
      .tg-list {
        .info {
          flex-direction: row;
          .info-item {
            &:first-child {
              margin-right: 220px;
            }

            .actions {
              display: flex;
              justify-content: space-between;
              margin: 10px;
              margin-top: 20px;
              width: 410px;
            }
          }

          .info-expand-icon {
            margin-top: 20px;
            margin-right: 20px;
          }

          @media (max-width: 992px) {
            justify-content: column;
            .info-item {
              &:nth-child(2) {
                position: absolute;
                right: 55px;
              }

              .actions {
                margin-right: 130px;
                width: 80vw;

                .button {
                  &:last-child {
                    position: absolute;
                    right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
