.dga-reverse-dob {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

  .dga-reverse-dob .layout-items-label,
  .dga-reverse-dob .error-message {
    flex-basis: 100%;
  }
