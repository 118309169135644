.activate-form {
  text-align: center;

  .resend {
    display: block;
    margin: 30px 0;

    &.active {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
