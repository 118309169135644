@import '../../../skin/theme/colors.css';

.WinLossContainer {
  display: flex;
  justify-content: center;

  .win-loss-section {
    padding: 10px 20px;

    .win-loss-header {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
    }

    .win-loss-amount {
      font-size: 16px;
      font-weight: 500;

      &.is-positive {
        color: var(--secondary-color);
      }
    }
  }
}
