body {
  --gameplay-topbar-height: 0px;
  --gameplay-header-height: 56px;
  --gameplay-header-bgcolor: var(--secondary-color);
  --gameplay-gamedetails-bgcolor: var(--secondary-color);
  --gameplay-bottombar-height: 0px;
  --gameplay-frame-darkmode-bgcolor: var(--black);
  --gameplay-popunder-overlay-bgcolor: var(--overlay);
  &.is-desktop {
    --gameplay-header-height: 60px;
    --gameplay-bottombar-height: 75px;
  }
  &.jurisdiction-sga {
    --gameplay-topbar-height: 30px;
  }
}

.PlayContainer {
  .rg-wrapper {
    background-color: var(--secondary-color);
  }

  .GamePlay {
    background-image: var(--gameplay-background-image);
    .GamePlayHeader {
      color: var(--white);

      &__user {
        .button {
          display: none;
        }
      }

      &__name {
        margin-right: 8px;
      }

      &__actions__name {
        margin: 0 12px;
      }

      &__menu,
      &__menu-close {
        margin-left: 8px;
      }

      .MembershipActions {
        .button:first-child {
          margin-right: 12px;
          margin-left: 30px;
          &::before {
            left: -33px;
          }
        }
      }
    }

    .GameDetails {
      color: var(--white);

      &__actions {
        .button {
          margin: 0 8px;
        }
      }

      &__quick-actions {
        .button.icon {
          margin: 0 8px;
        }
      }

      &__categories-nav {
        .CasinoCategoryMenu {
          max-width: 100vw;
          .category-item {
            border-radius: 3px;
            color: var(--white);

            &.active {
              background-color: var(--primary-color);
            }
          }

          &::before {
            display: none;
          }
        }
      }
    }

    .GameFrame {
      &__footer {
        display: none;
      }
    }
  }

  .ExpandableSection {
    &::before {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        var(--primary-color)
      );
    }
  }

  .QuickDeposit {
    form .cvv {
      margin-left: 12px;
    }
    .tg-select .Select select {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: calc(20px + var(--trailing-lane-width));
    }
    .QuickAmounts {
      .tg-input .Input {
        margin-left: 12px;
        overflow: hidden !important;
        .Input__status-line {
          display: none;
        }
      }
      > button {
        margin-left: 12px;
      }
      .amount-buttons {
        > button {
          margin: 0 12px;
        }
      }
    }

    @media (max-width: 992px) {
      form .cvv {
        margin-left: 0 !important;
      }

      .QuickAmounts .tg-input .Input {
        margin-left: auto;
      }
    }
  }
}
