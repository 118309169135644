body {
  --fallback-bg: #555;
  --fallback-linear-gradient: linear-gradient(
    90deg,
    rgba(186,186,186, 0) 0,
    rgba(186,186,186, 0.2) 20%,
    rgba(186,186,186, 0.5) 60%,
    rgba(186,186,186, 0)
  );
}

.skeleton {
  background: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2) 5%,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.2) 25%
  );
  background-size: 300% 100%;
  -webkit-animation: skeletonAnimation 2.5s ease infinite;
          animation: skeletonAnimation 2.5s ease infinite;
  display: inline-block;
}

.skeleton.bold {
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.3) 5%,
      rgba(0, 0, 0, 0.1) 15%,
      rgba(0, 0, 0, 0.3) 25%
    );
  }

.skeleton.line {
    margin: 2px 0;
    width: 80px;
    height: 100%;
    border-radius: 2px;
  }

.skeleton.line:before {
      content: '\00a0';
    }

.skeleton.circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

@-webkit-keyframes skeletonAnimation {
  0% {
    background-position: 300%;
  }
  100% {
    background-position: 0%;
  }
}

@keyframes skeletonAnimation {
  0% {
    background-position: 300%;
  }
  100% {
    background-position: 0%;
  }
}

.shine {
  background: var(--fallback-bg);
  position: relative;
  overflow: hidden;
  display: inline-block;
  opacity: 1;
}

.shine::after {
    content: '';
    position: absolute;
    inset: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-image: var(--fallback-linear-gradient);
    -webkit-animation: shimmer 3s infinite;
            animation: shimmer 3s infinite;
  }

@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
