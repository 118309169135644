@import '../../../skin/theme/colors.css';

.Step3 {
  .account-information {
    text-align: left;
    width: 100%;
    margin: 40px 0;
    line-height: 8px;

    .account-header {
      margin: 10px 0;
      padding: 0 0 15px;
      text-align: center;
      border-bottom: 2px var(--primary-text-color) dotted;

      .fullname {
        font-size: 22px;
        font-weight: bold;
        color: var(--primary-text-color);
      }

      .icon {
        font-size: 60px;
      }
    }

    .user-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
      line-height: 20px;
    }

    .birth-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
    }

    .contact-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
    }
  }

  .inner-limit-info {
    p {
      margin: 5px 0;
    }
  }

  .optin-information-holder {
    .optin-information-wrapper {
      border-bottom: 2px var(--primary-text-color) dotted;
      padding-bottom: 10px;
      text-align: left;
      line-height: 1em;

      .optin-information {
        width: 100%;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }

  .final-message {
    margin-top: 20px;
    text-align: left;
  }
}
