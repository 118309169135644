.IndexContainerPnp {
  .index__header__logo {
    display: none;
  }
}

.IndexContainerAccount {
  .index__header {
    .index__header__logo {
      display: none;
    }

    .index__header__actions__menu {
      display: initial;
      background: var(--white);
      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3);
      border-radius: 20%;
      width: 36px;
      height: 36px;
      padding: 4px;
      margin-top: 20px;

      img {
        width: 100%;
        height: 100%;

        @media (max-width: 360px) {
          width: 80%;
          height: 80%;
        }
      }
    }

    .index__header__actions__home {
      display: none;
    }
  }
}
