.responsiblegaming-limit-item-content {
  padding-bottom: 20px;
}

.timespan-selector {
  .button.primary {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.responsible-gaming {
  .pending-limit-content {
    margin-top: 20px;
  }
}

.responsible-gaming-logos {
  background: var(--secondary-color);
  border-radius: 0 0 8px 8px;
  padding: 5px;
  height: 30px;
}

.SessionClock {
  background: var(--secondary-color);
  border-radius: 0 0 8px 8px;
  padding: 5px;
  height: 30px;
}
