.ContentContainer {
  .main-content-container {
    .NavList {
      margin-right: 20px;
      ul {
        border-radius: 10px;

        li {
          a {
            opacity: 1;
          }
        }
      }
    }
  }
}
