.AccordionWidget .AccordionItem {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 5px;
  }

.AccordionWidget .AccordionItem__header {
      position: relative;
      text-align: left;
      padding: 8px 15px;
      min-height: 68px;
      cursor: pointer;
      background: #3a3a3a;
      -webkit-user-select: none;
              user-select: none;
      font-size: 15px;
      transition: background 0.17s;
    }

.AccordionWidget .AccordionItem__header:hover {
        background: rgba(58,58,58, 0.9);
      }

.AccordionWidget .AccordionItem__header .title-text,
      .AccordionWidget .AccordionItem__header span {
        line-height: 52px;
      }

.AccordionWidget .AccordionItem__header .info-amount {
        display: block;
        font-size: 16px;
        position: absolute;
        top: 8px;
        right: 55px;
        font-weight: 400;
        color: #eeeeee;
      }

@media (max-width: 375px) {

.AccordionWidget .AccordionItem__header .info-amount {
          font-size: 11px;
          right: 40px
      }
        }

.AccordionWidget .AccordionItem__header__expand-icon {
        position: absolute;
        right: 20px;
        top: 10px;
        pointer-events: none;
        font-size: 36px;
        -webkit-transform: scale(0.55);
                transform: scale(0.55);
        -webkit-transform-origin: center;
                transform-origin: center;
        transition: -webkit-transform 0.17s;
        transition: transform 0.17s;
        transition: transform 0.17s, -webkit-transform 0.17s;
        color: var(--darker-gray);
      }

.AccordionWidget .AccordionItem__header__tooltop-icon {
        display: none;
        font-size: 16px;
        position: absolute;
        top: 24px;
        right: 55px;
      }

.AccordionWidget .AccordionItem__header__tooltop-icon:hover {
          opacity: 0.8;
        }

.AccordionWidget .AccordionItem__header__tooltop {
        display: none;
        background: #ffffff;
        color: #000000;
        flex-basis: 100%;
        padding: 1px 15px;
        border-radius: 2px;
      }

.AccordionWidget .AccordionItem__header__tooltop::before {
          content: '';
          position: absolute;
          top: 45px;
          right: 54px;
          margin-left: -5px;
          border-width: 8px;
          border-style: solid;
          border-color: transparent transparent #ffffff transparent;
        }

@media (max-width: 375px) {

.AccordionWidget .AccordionItem__header__tooltop {
          font-size: 12px
      }
        }

.AccordionWidget .AccordionItem__content {
      background: #3a3a3a;
    }

.AccordionWidget .AccordionItem__content > div {
        padding: 8px 15px;
      }

.AccordionWidget .AccordionItem__content .no-list-data {
        padding: 10px 0 2px 0;
      }

.AccordionWidget .AccordionItem--expanded .AccordionItem__header__expand-icon {
        -webkit-transform: scale(0.65) rotate(180deg);
                transform: scale(0.65) rotate(180deg);
      }

.AccordionWidget .AccordionItem--expanded .AccordionItem__header__tooltop-icon,
      .AccordionWidget .AccordionItem--expanded .AccordionItem__header__tooltop {
        display: block;
      }

.AccordionWidget .AccordionItem--expanded .info-amount {
        display: none;
      }
