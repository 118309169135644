@import '../skin/theme/colors.css';

.tg-list {
  display: flex;
  flex-direction: column;

  .row {
    font-size: 12px;

    &.active {
      .detail {
        display: flex;
        position: relative;
        background: #353535;
        border-radius: 2px;
        margin: 10px 0;
        padding: 10px;
      }
      .info-expand-icon i {
        transform: rotate(180deg);
      }
    }

    .info {
      display: flex;
      position: relative;
      padding: 5px 0;
      margin: 5px 0;
      user-select: none;

      &.has-detail {
        cursor: pointer;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: var(--primary-color);
      }

      .info-expand-icon {
        margin-left: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        i {
          transition: transform 0.2s;
        }
      }
    }

    .info-item {
      flex: 1;

      p {
        margin: 0;
      }

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }

    .detail {
      padding: 0 15px;
      display: none;
      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        top: -6px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #353535;
      }
    }

    .detail-item > * {
      margin-right: 10px;
      flex: 1;
      &:last-child {
        overflow-wrap: anywhere;
      }
    }

    .info-item,
    .detail-item {
      display: flex;
      flex-direction: column;

      .bold {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  & + .navigation {
    display: flex;
    justify-content: space-between;
  }
}

.VerifyContainer {
  .detail-item > span {
    display: flex;
    flex-direction: column;
  }
}

body.ie {
  .tg-list {
    .row {
      .info-item,
      .detail-item {
        min-height: 40px;
      }
    }
  }
}
