.payment-wrapper {
  .cashier-accordion {
    .payment-result-wrapper {
      background: none;

      .payment-result-balance {
        color: var(--white);
      }
    }

    .payment-method-selector {
      .amount-selector {
        .button.secondary {
          margin: 0 8px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .custom-amount-and-submit {
        > .tg-input {
          margin-right: 4px;
        }

        > .button {
          margin-left: 4px;
        }
      }

      .payment-method-item-content .bonus-limit .bonus-code-input-wrapper {
        display: none;
      }
    }

    .fill-in-steps {
      background: var(--primary-color);
      padding: 10px 20px 20px;

      > div {
        .account-details {
          .tg-input,
          .tg-input.has-label {
            margin: 16px 0;

            select {
              height: 55px;
              line-height: 55px;
            }

            .select-icon {
              top: 0 !important;
              line-height: 55px;
            }
          }

          .layout-item > * {
            flex: 1 1 1%;
            margin: 0 0.5em 0 0;
          }

          .layout-item {
            .tg-input,
            .tg-input.has-label {
              margin: 0;
            }

            > div.tg-input:nth-child(2) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
