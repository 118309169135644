@import '../skin/theme/colors.css';

body {
  --fallback-bg: #555;
  --fallback-linear-gradient: linear-gradient(
    90deg,
    rgba(#bababa, 0) 0,
    rgba(#bababa, 0.2) 20%,
    rgba(#bababa, 0.5) 60%,
    rgba(#bababa, 0)
  );
}

$color: rgba(0, 0, 0, 0.2);
$boldcolor: rgba(0, 0, 0, 0.3);

.skeleton {
  background: $color;
  background-image: linear-gradient(
    45deg,
    $color 5%,
    rgba(0, 0, 0, 0.1) 15%,
    $color 25%
  );
  background-size: 300% 100%;
  animation: skeletonAnimation 2.5s ease infinite;
  display: inline-block;

  &.bold {
    background-image: linear-gradient(
      45deg,
      $boldcolor 5%,
      rgba(0, 0, 0, 0.1) 15%,
      $boldcolor 25%
    );
  }

  &.line {
    margin: 2px 0;
    width: 80px;
    height: 100%;
    border-radius: 2px;

    &:before {
      content: '\00a0';
    }
  }

  &.circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@keyframes skeletonAnimation {
  0% {
    background-position: 300%;
  }
  100% {
    background-position: 0%;
  }
}

.shine {
  background: var(--skeleton-bg, var(--fallback-bg));
  position: relative;
  overflow: hidden;
  display: inline-block;
  opacity: var(--skeleton-opacity, 1);

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: var(--skeleton-gradient, var(--fallback-linear-gradient));
    animation: shimmer 3s infinite;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
