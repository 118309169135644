$color: #ed6458;

body {
  &.jurisdiction-sga .EventCalendarContainer {
    .main-content-container {
      margin-top: calc(
        var(--rg-bar-height) + var(--header-height-mobile)
      ) !important;

      @media (min-width: 993px) {
        margin-top: calc(
          var(--rg-bar-height) + var(--header-height)
        ) !important;
      }
    }

    .main-content-container,
    .main-content-container > .container {
      min-height: calc(
        100vh - var(--rg-bar-height) - var(--header-height-mobile)
      ) !important;

      @media (min-width: 993px) {
        min-height: calc(
          100vh - var(--rg-bar-height) - var(--header-height)
        ) !important;
      }
    }
  }

  &:not(.jurisdiction-sga) .EventCalendarContainer {
    .main-content-container {
      margin-top: var(--header-height-mobile) !important;

      @media (min-width: 993px) {
        margin-top: var(--header-height) !important;
      }
    }

    .main-content-container,
    .main-content-container > .container {
      min-height: calc(100vh - var(--header-height-mobile)) !important;

      @media (min-width: 993px) {
        min-height: calc(100vh - var(--header-height)) !important;
      }
    }
  }
}

.EventCalendarContainer {
  .main-content-container {
    background-size: cover;
    background-position: center;
  }

  .Footer {
    position: relative;
  }

  /* .main-content-container,
  .main-content-container > .container {
    &:before,
    &:after {
      z-index: -1;
      content: '';
      position: fixed;
      top: -3000%;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url(../images/snow.png);
      background-size: cover;
      animation: move-bg 500s linear infinite;
    }
  }

  .main-content-container > .container {
    &:before {
      filter: blur(4px);
      opacity: 0.8;
      top: -2500%;
      background-size: 60%;
      animation-duration: 400s;
    }

    &:after {
      filter: blur(2px);
      opacity: 0.8;
      top: -2000%;
      background-image: url(../images/snow.png);
      background-size: 70%;
      animation-duration: 300s;
    }
  } */

  .event-calendar-content {
    padding: 10px;
  }

  .Calendar,
  .Question {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
  }

  .Calendar {
    > div > p > img {
      height: 150px;
      max-width: 100%;
    }

    .calendar-days {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;

      .calendar-day {
        border-radius: 1em;
        border: 4px solid $color;
        height: 250px;

        &.isBeforeToday {
          opacity: 0.7;
        }

        .index,
        .header {
          background-color: $color;
          font-weight: bold;
          position: absolute;
        }

        .index {
          top: 0;
          left: 0;
          border-radius: 0 0 1em 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .header {
          border-radius: 1em 1em 0 0;
          padding: 4px 10px 0 10px;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 80%;
          text-align: center;
        }
      }

      @media (min-width: 993px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .Question {
    position: relative;
    max-width: 400px;
    background: $color;
    border-radius: 1em;
    margin: 0 auto;
    padding: 10px;

    .question-cta {
      margin: 10px 0;
    }

    .question-close {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 0 0 1em 0;
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color;
      padding-bottom: 5px;
      padding-right: 5px;

      i {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        background-image: url('../images/close.svg');
        width: 15px;
        height: 15px;
      }
    }

    .question-image {
      width: 100%;
      height: 150px;
      border-radius: 1em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .question-extra-info {
      font-size: 0.8em;
    }
  }
}

@keyframes move-bg {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(70%);
  }
}
