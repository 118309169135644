.responsible-gaming-logos {
  z-index: 999;
  display: flex;
  top: 0;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 30px;
}
.responsible-gaming-logos .responsible-gaming-logo {
    display: flex;
    align-items: center;
  }
.responsible-gaming-logos .responsible-gaming-logo img {
      height: 22px;
      margin: 0 2px;
    }
