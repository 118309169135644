.Popup {
  &__content {
    border-radius: 10px;

    .reality-check {
      .header {
        background: var(--primary-color);
      }
    }
  }

  &__children {
    .SourceOfWealthContainer {
      .alternatives {
        .tg-checkbox {
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }

    .RealityCheckContainer {
      .actions {
        .button {
          &:last-child {
            width: 100%;
            margin: 15px 0;
          }
        }
      }
    }
  }
}
