@import '../skin/theme/colors.css';

.AcceptUpdatedTaC {
  display: flex;
  align-items: center;
  flex-direction: column;

  > * {
    margin-bottom: 10px;
  }

  header {
    margin-top: 10px;
    text-align: center;
    order: 1;
  }

  .button {
    order: 2;
  }

  &__content {
    margin: 0 auto;
    max-width: 500px;
    border-radius: 2px;
    padding: 0 10px;
    height: 400px;
    overflow-y: scroll;
    background-color: var(--gray);
    color: var(--black);
    order: 3;
    text-align: left;

    * {
      white-space: normal;
      word-break: break-word;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1em;
      font-weight: bold;
    }
  }
}
