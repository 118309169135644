@import '../skin/theme/colors.css';

ul.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  li {
    display: inline;
    cursor: pointer;
  }

  a {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    text-decoration: underline;
  }

  .active > a {
    z-index: 2;
    color: var(--white);
    cursor: default;
  }

  .disabled > a {
    color: var(--gray);
    cursor: not-allowed;
  }
}
