@import '../skin/theme/colors.css';

.ResponsibleGamingWidget {
  &__limit-content {
    position: relative;

    &--valid {
      & > button {
        animation-name: pulse;
        animation-duration: 0.3s;
        animation-iteration-count: 3;
        animation-timing-function: ease-in-out;
      }
    }
  }

  &__timespan,
  &__alert {
    margin: 0 0 15px 0;
  }

  &__timespan-title,
  &__timespan-row,
  &__timespan-limit-selector {
    margin-bottom: 3px;
  }

  &__timespan-title {
    font-size: 16px;
    font-weight: 700;
  }

  &__timespan-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__timespan-box {
    padding: 20px;
    text-align: center;

    > span {
      display: block;
      margin-bottom: 3px;
    }
  }

  &__timespan-box-actions {
    display: flex;
    justify-content: space-evenly;
  }

  &__timespan-limit-selector {
    display: flex;
    padding: 0;

    .tg-select,
    .tg-input {
      flex: 1;

      input,
      select {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      &__status-text {
        display: none;
      }
    }

    .button {
      margin: 0 0 0 0.5em;
      width: 46px;
    }
  }

  .Loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.9);
  }

  .AdminLimits {
    margin-bottom: 10px;

    .Alert {
      margin: 0;
    }
  }
}

@keyframes pulse {
  0% {
    background: var(--primary-color);
  }

  50% {
    background: var(--primary-color-hover);
  }

  100% {
    background: var(--primary-color);
  }
}
