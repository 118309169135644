.signup-step-wrapper {
  text-align: center;
  margin-bottom: 10px;
}

.password-hint {
  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }
}
