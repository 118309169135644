main {
  .error-background {
    color: var(--primary-color);

    .error-content {
      span {
        color: var(--primary-color);
        margin: 0 auto;
      }
    }
  }
}
