@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.LoadingPopup {
  &--inline {
    height: 100%;
  }

  &:not(&--inline) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .loader-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;

  .loader-icon {
    position: relative;
    z-index: 2;
    background-image: url('/bh-fist.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 160px;
    height: 128px;
    animation: pulsate linear 500ms infinite;
  }
}

.swish-loader {
  height: 0.5em;
  width: 3.5em;
  position: relative;
  font-size: 20px;

  .loader-dot,
  .dot-1,
  .dot-2,
  .dot-3 {
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background: #fff;
    animation-name: loader-wave;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
  }

  .dot-1 {
    left: 1em;
    animation-delay: 200ms;
  }
  .dot-2 {
    left: 2em;
    animation-delay: 400ms;
  }
  .dot-3 {
    left: 3em;
    animation-delay: 600ms;
  }
}

@keyframes loader-wave {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-0.5em);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
