.section.products {
  flex-direction: column;
  align-items: center;

  .ItemWithIconAndText {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    width: 240px;
    height: 40px;
    padding: 0 16px;
    margin: 10px 0;
    text-transform: uppercase;
    color: #fdd000;
    background: linear-gradient(
      90deg,
      #bf2e1f 2%,
      #bf2e1f 36%,
      rgba(191, 46, 31, 0) 73%,
      rgba(191, 46, 31, 0) 98%
    );

    &:nth-child(even) {
      flex-direction: row-reverse;
      background: linear-gradient(
        270deg,
        #bf2e1f 2%,
        #bf2e1f 36%,
        rgba(191, 46, 31, 0) 73%,
        rgba(191, 46, 31, 0) 98%
      );
    }

    .text {
      font-size: 1.3em;
      margin-top: 0;
      order: -1;
    }

    &:hover {
      background: #bf2e1f;
    }
  }
}
