.InternalMessagesContainer {
  .messages-list-item {
    background: var(--primary-color);

    &:not(.open) {
      &:hover {
        background: var(--primary-color);
      }
    }
  }
}
