.activate-form {
  text-align: center;
}

  .activate-form .resend {
    display: block;
    margin: 30px 0;
  }

  .activate-form .resend.active {
      cursor: pointer;
      text-decoration: underline;
    }
