.IndexContainerAccount {
  .StickyFooter-mobile {
    display: none;
  }

  @media (max-width: 992px) {
    .OffCanvas {
      height: 100%;
    }
  }

  .PageBuilder {
    > div:last-child {
      padding-bottom: 40px;
    }

    @media (max-width: 993px) {
      > .Section,
      .GameRow__title {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .index-hero {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;

    .container {
      max-width: none;

      @media (max-width: 530px) {
        font-size: 20px;
      }

      img {
        max-width: 400px;

        @media (max-width: 992px) {
          max-width: 300px;
        }

        @media (max-width: 320px) {
          max-width: 250px;
        }
      }

      h2 {
        text-transform: uppercase;
        text-shadow: -10px 0 10px rgba(0, 171, 198, 0.22);

        &:nth-child(2) {
          color: var(--secondary-color);
          margin-top: 40px;
          margin-bottom: 0;
        }

        &:nth-child(3) {
          color: var(--primary-color);
          transform: skewX(-10deg);
          overflow: hidden;
          margin-top: 0;
          margin-bottom: 40px;

          &::first-letter {
            text-shadow: -12px 0 60px #00abc6;
          }
        }
      }
    }
  }

  .index-actions-pnp,
  .index-actions-account {
    text-align: center;
    font-size: 16px;

    @media (min-width: 993px) {
      font-size: 26px;
    }
  }

  .index-actions-account {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      margin: 0 20px;
    }

    @media (max-width: 992px) {
      flex-direction: column;

      > div {
        margin: 20px 0;
        min-width: 365px;
        max-width: 100%;
        padding: 0 60px;

        .button {
          width: 100%;
        }
      }
    }
  }

  .GameRow {
    margin: 40px auto 0;
    text-align: center;

    .index-game-category-action,
    .index-sport-category-action {
      margin: 40px;
      display: inline-block;
      font-size: 0.7rem;
    }
    .index-sport-category-action {
      margin: 0;
      a {
        background: var(--secondary-color);
      }
    }

    @media (max-width: 993px) {
      display: flex;
      flex-direction: column;

      .GameRow__items {
        width: 100%;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      color: var(--primary-text-color);
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    @media (max-width: 1025px) {
      .Game {
        min-width: initial;
      }

      .slider .list .track .items .slide-item {
        margin-right: 5px;
        min-width: 45vw;
      }
    }
  }

  .Footer {
    border-top: none;
  }

  .main-content-container {
    background-size: 107vw 100%;
    background-position: top center;
    justify-content: center;
  }
}
