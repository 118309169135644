.Footer {
  background: var(--main-background);
  border-top: 2px solid #003e5d;

  .topSection {
    background: transparent;

    .container {
      flex-direction: column;

      nav {
        order: -1;

        @media (max-width: 992px) {
          flex-direction: column;
        }
      }

      nav {
        align-items: center;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .content-container {
    .section {
      &.one {
        .footer-logo {
          display: none;
        }

        > div {
          flex-wrap: wrap;
        }
      }

      &.two {
        padding-bottom: 0;
      }

      &.three {
        .corporation-logo {
          img {
            height: 180px;
          }
        }

        h3 {
          display: inline-block;
          background: var(--main-background);
          margin: 0;
          padding: 0 8px;
        }

        .other-brands {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          width: max-content;
          max-width: 100%;
          border: 2px solid var(--white);
          border-radius: 20px;
          padding: 16px;
          margin: -10px auto 0 auto;

          img {
            display: block;
            height: 65px;
            margin: 0 5px;

            @media (max-width: 992px) {
              height: 50px;
            }
          }

          a {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
