.Loader {
  background: transparent;

  .loader-icon {
    background-image: url('../images/spinner.gif');
    height: auto;
    width: 150px;
    padding-bottom: 100%;
  }

  .loading-spinner {
    display: none;
  }
}
