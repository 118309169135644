.header-wrapper {
  .Header {
    &__logo {
      margin-left: -17px;
    }

    &__background {
      &.Header__background--visible {
        background: var(--secondary-color);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
      }
    }

    &__topnav {
      .top-nav-menu-item {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;

        &.active {
          color: #003e5d;
        }
      }
    }

    &__actions {
      .MembershipActions {
        .button:first-child {
          margin-right: 12px;
          margin-left: 30px;
          &::before {
            left: -33px;
          }
        }
      }
    }
  }
}

@media (max-width: 993px) {
  .header-wrapper {
    .Header {
      padding: 0;
      margin-right: 10px;
    }
    .MembershipActions {
      font-size: 10px;
    }
  }
}

@media (max-width: 370px) {
  .header-wrapper {
    .Header {
      margin-left: 3px;
      .Header__logo {
        img {
          width: 100px;
        }
      }
    }
    .MembershipActions {
      font-size: 9px;
    }
  }
}

@media (max-width: 300px) {
  .header-wrapper {
    .Header {
      .Header__logo {
        img {
          width: 90px;
        }
      }
    }
    .MembershipActions {
      margin-left: -10px;
      font-size: 7px;
    }
  }
}
