.LanguageSelectorList {
  width: 100%;
  padding: 8px 0;
  background: linear-gradient(
    90deg,
    rgba(191, 46, 31, 0) 5%,
    rgba(191, 46, 31, 0.8) 30%,
    #bf2e1f 50%,
    rgba(191, 46, 31, 0.8) 70%,
    rgba(191, 46, 31, 0) 95%
  );
  flex-wrap: wrap;

  li {
    cursor: pointer;
    opacity: 0.5;
    margin: 10px;

    @media (min-width: 992px) {
      margin: 0 7px;
    }

    img {
      border-radius: 5px;
      width: 50px;
      height: 30px;
    }

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  span {
    display: none;
  }
}

.is-pnp {
  .Footer {
    .LanguageSelectorList {
      display: none;
    }
  }
}

@media (min-width: 993px) {
  .LanguageSelectorList {
    display: flex;
  }

  .LanguageSelector {
    display: none;
  }
}
