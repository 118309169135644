.section.profile-links {
  flex-flow: row wrap;
  align-items: center;
  white-space: nowrap;
  margin: 20px 0;

  .ItemWithIconAndText {
    margin: 5px 0;
    padding: 0;
    width: 50%;

    .text {
      margin: 0;
    }
  }
}
