.SignUpContainer {
  form.BonusStep {
    margin-bottom: 20px;

    div + a {
      display: none;
    }
  }

  .Step3 {
    .layout-item {
      margin-top: 20px;
    }
    .account-information {
      > div {
        border-color: var(--white);
      }

      .account-header {
        p.fullname {
          color: var(--white);
        }
      }
    }
  }

  /* Adding more space to each input - have to target many classes as they differ */
  .SetLimitsStep {
    margin-top: 75px;

    .layout-item .button:first-child {
      margin-right: 1.5em;
    }
  }

  .signup-form {
    margin-top: 75px;

    .tg-select {
      margin-bottom: 25px;
    }

    .tg-checkbox {
      margin-bottom: 10px;
    }

    .layout-item {
      .tg-input:first-child,
      .tg-select:first-child {
        margin-right: 20px;
      }
    }

    .tg-checkbox__status-text {
      left: 5px;
      top: -30px;
      right: auto;
    }

    .tg-birthdate-input__status-text {
      width: initial;
      left: 90px;
      top: -10px;
      right: auto;
    }

    .tg-birthdate-input {
      margin-bottom: 25px;
      .Select {
        margin-right: 20px;
        margin-left: 0;
      }

      div:nth-last-child(3) {
        margin-right: 0;
      }

      .tg-birthdate-input__label {
        margin-bottom: 10px;
      }
    }

    > .layout-item.Checkbox {
      margin: 10px 0 0;
    }

    &.Step2 .tg-input.layout-item-4 .select-icon {
      top: 10px;
    }

    .layout-items-label {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }

  .buttons {
    & > :first-child {
      padding-right: 8px;
    }

    & > :last-child {
      padding-left: 8px;
    }
  }
  /* Progress bar */
  .Step1,
  .Step2,
  .SetLimitsStep {
    &::before {
      display: inline-block;
      content: '';
      width: auto;
      height: 10px;
      position: absolute;
      left: 20px;
      right: 20px;
      top: 120px;
      background: #fff;
    }

    &::after {
      font-size: 12px;
      display: inline-block;
      width: 100%;
      position: absolute;
      top: 134px;
      color: var(--white);
      transform: translateX(0%);
    }
  }

  .SetLimitsStep {
    &:before {
      background-image: linear-gradient(
        to right,
        var(--primary-color) 0%,
        var(--primary-color) 100%
      );
      background-repeat: no-repeat;
      background-size: 75% 100%;
      animation: bar75 2s ease;
    }

    &:after {
      content: '75%';
      animation: text75 2s ease;
      transform: translateX(67%);
    }
  }

  .Step1 {
    &:before {
      background-image: linear-gradient(
        to right,
        var(--primary-color) 0%,
        var(--primary-color) 100%
      );
      background-repeat: no-repeat;
      background-size: 25% 100%;
      animation: bar25 2s ease;
    }
    &:after {
      content: '25%';
      animation: text25 2s ease;
      transform: translateX(21%);
    }
  }

  .Step2 {
    &:before {
      background-image: linear-gradient(
        to right,
        var(--primary-color) 0%,
        var(--primary-color) 100%
      );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      animation: bar50 2s ease;
    }

    &:after {
      content: '50%';
      animation: text50 2s ease;
      transform: translateX(44%);
    }
  }

  .Step3,
  .Step3.form {
    padding: 0;
    margin: 0;

    p {
      margin: 0;
    }

    .account-information {
      margin: 0;
      line-height: inherit;

      .account-header i {
        margin: 0 0 15px;
      }

      .account-header,
      .user-info,
      .birth-info,
      .contact-info,
      .optin-information-holder,
      .optin-information-holder .optin-information-wrapper {
        padding: 0;
        margin: 0 0 15px;
        line-height: inherit;
        border: 0;
      }
    }

    .layout-item .button:first-child {
      margin-right: 1.5em;
    }

    .buttons-wrapper {
      margin: 0;
    }
  }
}

.ResponsibleGamingWidget {
  .AccordionItem__header__tooltop {
    margin-top: 32px;
  }

  form {
    padding-bottom: 0 !important;
    text-align: left;
    > button.primary.wide {
      margin-top: 2em;
    }
  }

  &__timespan {
    margin: 0px 0 20px 0;

    &-row {
      margin-top: 10px;
    }

    &-title {
      margin-bottom: 10px;
    }

    &-limit-selector {
      .tg-input,
      .tg-select {
        margin: 0 !important;
        .Input input {
          width: 100%;
        }
      }

      .button.mini.tertiary {
        padding: 0 2em;
        margin-left: 1.2em;
        width: auto;
      }
    }
  }
}

@media (max-width: 993px) {
  .Step1,
  .Step2,
  .SetLimitsStep {
    &:before {
      top: 125px;
    }

    &:after {
      top: 140px;
    }
  }
}

@keyframes text25 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(21%);
  }
}

@keyframes text50 {
  0% {
    transform: translateX(21%);
  }
  100% {
    transform: translateX(44%);
  }
}

@keyframes text75 {
  0% {
    transform: translateX(44%);
  }
  100% {
    transform: translateX(67%);
  }
}

@keyframes bar25 {
  0% {
    background-size: 0% 100%;
  }
  100% {
    background-size: 25% 100%;
  }
}

@keyframes bar50 {
  0% {
    background-size: 25% 100%;
  }
  100% {
    background-size: 50% 100%;
  }
}

@keyframes bar75 {
  0% {
    background-size: 50% 100%;
  }
  100% {
    background-size: 75% 100%;
  }
}
