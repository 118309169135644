.HistoryContainer {
  .WinLossContainer {
    .win-loss-section {
      .win-loss-amount {
        &.is-positive {
          color: var(--success);
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
