@import '../../skin/theme/colors.css';
@import '../../skin/theme/variables.css';

.responsible-gaming-logos {
  z-index: 999;
  display: flex;
  top: 0;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: var(--rg-bar-height);

  .responsible-gaming-logo {
    display: flex;
    align-items: center;
    img {
      height: 22px;
      margin: 0 2px;
    }
  }
}
