.is-offcanvas-open {
  .toast {
    position: absolute;
    left: 0;
    width: auto;
    right: 500px;
    overflow-wrap: anywhere;
  }

  @media (max-width: 500px) {
    .toast {
      right: 0;
      margin: 0;
    }
  }

  @media (min-width: 501px) and (max-width: 700px) {
    .toast {
      width: 100%;
      .toast-content {
        margin-left: calc(100% - 500px);
      }
    }
  }

  @media (min-width: 701px) and (max-width: 992px) {
    .toast {
      width: 100%;
    }
  }
}
