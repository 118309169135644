body {
  --pnp-width: 450px;
}

.IndexContainerAccount {
  .PayNPlay {
    &.amount {
      padding: 0 2em;
    }
  }
}

.PayNPlay {
  &.contact, &.bonus {
    max-width: 350px;
  }

  .Input,
  .Select {
    &__trailing-lane {
      display: none;
    }

    &__label {
      color: #363636;
    }

    input {
      caret-color: #363636;
    }
  }

  .Amount {
    form {
      button {
        width: 100%;
        font-size: 1.5em;
        @media screen and (max-width: 992px) {
          font-size: 1em;
        }

        @media (max-width: 992px) {
          &:before {
            display: none;
          }
        }
      }
    }

    &__custom-amount {
      input {
        font-size: 3.5em !important;
        @media screen and (max-width: 992px) {
          font-size: 2.5em !important;
        }
        line-height: 1em;
        text-align: center;
        font-weight: bold;
        color: var(--secondary-color);

        &::placeholder {
          font-weight: normal;
          color: rgba(var(--secondary-color), 0.3);
        }
      }
    }

    &__question {
      button {
        line-height: inherit;
        background: none;
        box-shadow: none;
        font-size: inherit;
        font-weight: inherit !important;
        text-transform: unset;
        padding: 0;
        text-decoration: underline;
        font-style: italic;
        color: #363636;

        &:hover {
          background: none;
          color: #363636;
        }
      }
    }
  }

  .Contact {
    .button {
      width: 100%;
      margin-top: 30px;
    }
  }

  .Bonus {
    .BonusSelector {
      margin-bottom: var(--pnp-spacing);
      color: #ffffff;

      h3 {
        margin: 45px 0 5px 0;
      }

      &__bonus {
        position: relative;
        padding: 15px;
        border-radius: 10px;
        margin-top: 50px;
        background-color: #ff5850;

        &__content {
          text-align: left;

          * {
            margin: 0;
          }

          img {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            object-position: center;
            object-fit: cover;
            position: absolute;
            border: 2px solid var(--primary-color);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -30px;
          }
        }

        button {
          margin-top: var(--pnp-spacing);
        }
      }
    }
  }
}
