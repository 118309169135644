@import '../../skin/theme/colors.css';

.leaderboard {
  padding: 20px;
  position: relative;
  background: var(--primary-color);

  table {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
  }

  .leaderboard-header-item {
    color: var(--white);
    text-align: left;

    th:last-child {
      text-align: right;
    }
  }

  .leaderboard-item {
    line-height: 40px;

    td {
      position: inherit;
      padding: 0 10px;

      &:last-child {
        text-align: right;
      }
    }
  }

  .leaderboard-item-active {
    font-weight: bold;
  }

  tbody.relative {
    border-top: 1px solid var(--white);
  }

  @media (max-width: 540px) {
    font-size: 0.9em;
  }
}

.tournament-join {
  .button.primary.icon {
    padding: 0 3em;
    display: flex;
    align-items: center;

    i {
      padding-right: 0;
    }

    &.loading {
      height: 42px;

      svg {
        margin: 0;
      }

      circle {
        stroke: var(--primary-btn-color);
      }
    }
  }
}
