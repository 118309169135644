.Game {
  &.large-thumbnail {
    .thumbnail::before {
      font-size: 88px;
    }
  }

  &.is-closed {
    .overlay span {
      color: var(--white);
    }
  }

  .thumbnail {
    &::before {
      content: '\e90f';
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5% 12%;
      border-radius: 10px;
      transform: translate(-50%, -150%) rotate(-180deg);
      background: rgba(0, 0, 0, 0.6);
      color: var(--white);
      font: normal normal normal 44px/1 'icomoon';
      transition: all 150ms;
      opacity: 0;
    }

    &:hover {
      &::before {
        transform: translate(-50%, -50%) rotate(-180deg);
        opacity: 1;
      }
    }

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35%;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    }
  }

  &__favorite-heart.button.icon {
    border-radius: 0;
    background: none;
    box-shadow: none;
  }

  .game-info {
    color: var(--white);
    position: absolute;
    bottom: 5px;
    left: 0;
    pointer-events: none;
  }

  .badges {
    top: 10px;
    left: 10px;
    bottom: initial;
  }

  .jackpot {
    color: var(--white);
    background: rgba(0, 0, 0, 0.2);
    top: 1px;
    bottom: initial;
    padding: 7px 0;

    img {
      height: 16px;
    }
  }

  &__favorite-heart.button.icon {
    border: none;
    box-shadow: none;
    background: none;
  }
}
