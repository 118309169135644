body.from-app {
  .OffCanvas {
    .canvas-content {
      max-width: none;
    }

    .canvas-close {
      visibility: hidden;
    }
  }
}
