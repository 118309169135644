.Game {
  .live-casino-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin: 4px 0 0;
    .wager-limits {
      font-weight: 700;
      font-size: 10px;
    }
    .roulette-numbers {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      font-weight: 700;
      font-size: 10px;
      height: 25px;
      .number {
        margin: 0 3px;
        width: 12px;
        height: 16px;
        text-align: center;
        font-size: 11px;
        display: flex;
        justify-content: center;
        &.red:after,
        &.red:before {
          background: #b20000;
        }
        &.black:after,
        &.black:before {
          background: #181818;
        }
        &.green:after,
        &.green:before {
          background: #007523;
        }
        &:before,
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          width: 12px;
          height: 16px;
          z-index: -1;
        }
        &:before {
          margin-left: -2px;
        }
        &:after {
          margin-left: 2px;
        }
        &:nth-child(odd) {
          &:before {
            transform: skew(10deg, 0deg);
          }
          &:after {
            transform: skew(-10deg, 0deg);
          }
        }
        &:nth-child(even) {
          &:before {
            transform: skew(-10deg, 0deg);
          }
          &:after {
            transform: skew(10deg, 0deg);
          }
        }
        &:first-child {
          margin: 0 8px;
          transform: scale(1.5);
          font-size: 9px;
          align-items: center;
          &:before {
            border: 1px solid white;
            border-right: 0;
          }
          &:after {
            border: 1px solid white;
            border-left: 0;
          }
        }
      }
    }
    .blackjack-info {
      display: flex;
      align-items: center;
      font-size: 10px;
      .seats,
      .unlimited-seats,
      .bet-behind {
        border-radius: 100%;
        border: 1px solid #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin: 0 0 0 5px;
        &.red {
          background: #b20000;
        }
        &.orange {
          background: #d95e01;
        }
        &.yellow {
          background: #bca700;
        }
        &.green {
          background: #007523;
        }
        &.gray {
          background: #707070;
        }
      }
      .unlimited-seats {
        img {
          width: 13px;
        }
      }
      .bet-behind {
        img {
          width: 11px;
        }
      }
    }
    @media (max-width: 992px) {
      display: block;
      height: auto;
      .wager-limits {
        font-size: 10px;
        line-height: 13px;
      }
      .roulette-numbers {
        position: inherit;
        transform: scale(0.8);
        height: 20px;
        transform-origin: left bottom;
        justify-content: flex-end;
        align-items: flex-start;
        .number {
          &:first-child {
            line-height: 9px;
          }
        }
      }
      .blackjack-info {
        position: inherit;
        .seats,
        .unlimited-seats,
        .bet-behind {
          width: 17px;
          height: 17px;
          font-size: 8px;
          margin: 0 0 0 2px;
          img {
            width: 9px;
          }
        }
        .bet-behind {
          img {
            width: 7px;
          }
        }
      }
    }
  }
}
