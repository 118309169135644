body {
  background: url('../images/pattern.svg');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;

  .main-content-container {
    color: var(--primary-text-color);
  }

  .flex {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    &:not(.button) {
      text-decoration: underline;
    }
  }

  .ContentPreviewContainer {
    z-index: 1000;
  }

  @media (max-width: 992px) {
    #launcher:not(.open) {
      /* Important required to override Zendesks style */
      display: none;
    }
  }
}

body,
.button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Futura, 'Futura LT', sans-serif;
}

#fc_frame:not(.fc-open) {
  @media (min-width: 993px) {
    display: initial;
  }
}

#fasttrack-crm .show-message-button {
  background-color: var(--primary-btn-background) !important;
  color: white !important;
}