nav.categories {
  line-height: 50px;
  padding: 0 20px;
  background: #00abc6;
}

nav.categories a {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: #ffffff;
  margin-right: 20px;
  text-decoration: none;
  opacity: 0.5;
  padding-bottom: 11px;
}

nav.categories a.active {
  opacity: 1;
  border-bottom: 3px solid #ff5850;
}
