@import '../../skin/theme/colors.css';

body {
  .SessionSummary {
    font-size: 12px;

    .Header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
    }

    .Summary {
      margin-bottom: 20px;

      .date {
        display: inline;
        color: #aaaaaa;
      }

      .col {
        display: grid;
        grid-gap: 0 15px;

        &.col-1 {
          grid-template-columns: repeat(1, 1fr);
        }

        &.col-2 {
          grid-template-columns: repeat(2, 1fr);
          margin-top: 20px;
        }
      }

      .info {
        margin-top: 5px;

        span {
          white-space: nowrap;

          &:nth-child(2) {
            color: #aaaaaa;
          }
        }

        span.green-value {
          color: var(--secondary-color);
        }
      }
    }

    .deposit-limit {
      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0 5px 0;
      }
      .limits {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        span {
          width: 100%;
        }
      }
    }

    .ResponsibleGaming {
      margin-top: 20px;

      h2,
      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0 5px 0;
      }

      p {
        margin: 0;
      }

      a {
        color: var(--primary-color);
      }
    }
  }
}
