.SignUpContainer.v1 .SetLimitsStep > * {
    margin: 0 0 15px;
  }
  .SignUpContainer.v1 .SetLimitsStep form {
    padding-bottom: 0;
  }
  .SignUpContainer.v1 .SetLimitsStep__summary-item {
    display: flex;
    margin-bottom: 3px;
  }
  .SignUpContainer.v1 .SetLimitsStep__summary-item :first-child::after {
      content: '-';
      margin: 0 3px;
    }
  .SignUpContainer.v1 .SetLimitsStep__summary-item :last-child {
      margin-right: auto;
    }
