.dga-reverse-dob {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  .layout-items-label,
  .error-message {
    flex-basis: 100%;
  }
}
