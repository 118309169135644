.IndexContainerPnp {
  .Footer {
    border-top: none;
  }

  .main-content-container {
    margin-top: 0 !important;
    @media (min-width: 1300px) {
      background-color: var(--main-background);
    }

    .index-container-full {
      background: url('../images/index-bg.jpg') var(--white);
      background-size: 107vw 100%;
      background-position: top center;
      justify-content: center;
    }

    .index-background-active {
      background: none;
    }

    .fixed-position {
      margin-top: 0;
    }

    background: none;

    .Features {
      display: none;
    }

    .brand-description {
      max-width: none;

      @media (max-width: 530px) {
        font-size: 20px;
      }

      img {
        max-width: 400px;

        @media (max-width: 992px) {
          max-width: 300px;
        }

        @media (max-width: 320px) {
          max-width: 250px;
        }
      }

      h2 {
        text-transform: uppercase;
        text-shadow: -10px 0 10px rgba(0, 171, 198, 0.22);

        &:nth-child(2) {
          color: var(--secondary-color);
          margin-bottom: 0;
        }

        &:nth-child(3) {
          color: var(--primary-color);
          transform: skewX(-10deg);
          overflow: hidden;

          &::first-letter {
            text-shadow: -12px 0 60px #00abc6;
          }
        }
      }
    }

    .actions {
      align-items: center;

      .primary,
      .secondary {
        width: auto;
        padding: 0 30px;
      }

      @media (max-width: 992px) {
        flex-direction: column;

        .primary,
        .secondary {
          width: 70vw;
          font-size: 1.5em;
        }
      }
    }
  }
}

.Amount__custom-amount {
  input::placeholder {
    font-size: 0.8em;
  }
}

.Amount__tac {
  display: none;
}

.index-terms, .index-promotion-terms {
  max-width: var(--pnp-width);
  margin: auto;
  padding: 0 2em;
}

.jurisdiction-sga {
  .index-container-full {
    margin-top: var(-rg-bar-height);
  }

  .IndexContainerAccount {
    .main-content-container {
      margin-top: 0 !important;
      @media (min-width: 1300px) {
        background-color: var(--main-background);
      }

      .PageBuilder {
        padding-top: var(--rg-bar-height);
      }
    }
  }
}

.jurisdiction-mga {
  .IndexContainerAccount {
    .main-content-container {
      @media (min-width: 1300px) {
        background-color: var(--main-background);
      }
    }
  }
}
