.notification {
  .timeout-bar {
    background: var(--secondary-color);
  }

  .notification-buttons {
    color: var(--secondary-color);

    > a {
      color: var(--secondary-color);
    }
  }
}
