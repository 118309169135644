@font-face {
  font-family: 'Futura LT';
  font-style: normal;
  font-weight: 400;
  src: url('/skin/fonts/Futura LT Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura LT';
  font-style: italic;
  font-weight: 400;
  src: url('/skin/fonts/Futura LT Medium Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura LT';
  font-style: normal;
  font-weight: 700;
  src: url('/skin/fonts/Futura LT Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura LT';
  font-style: italic;
  font-weight: 700;
  src: url('/skin/fonts/Futura LT Bold Oblique.ttf') format('truetype');
}
