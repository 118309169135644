.SignUpContainer.v1 .SetLimitsStep {
  > * {
    margin: 0 0 15px;
  }

  form {
    padding-bottom: 0;
  }

  &__summary-item {
    display: flex;
    margin-bottom: 3px;

    :first-child::after {
      content: '-';
      margin: 0 3px;
    }

    :last-child {
      margin-right: auto;
    }
  }
}
