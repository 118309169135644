@import '../skin/theme/colors.css';

$toggle-width: 45px;
$toggle-height: 20px;
$toggle-dot-width: 14px;
$toggle-dot-height: 14px;
$toggle-dot-offset: 4px;

.Checkbox {
  display: flex;
  align-items: center;

  &__input-wrapper {
    margin: 0;
    width: $toggle-width;
    height: $toggle-height;
    background: var(--toggle-unchecked);
    border-radius: 99px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &::after {
      top: 0;
      bottom: 0;
      margin: auto;
      left: $toggle-dot-offset;
      pointer-events: none;
      position: absolute;
      content: '';
      width: $toggle-dot-width;
      height: $toggle-dot-height;
      border-radius: 50%;
      background: var(--toggle-dot);
      transition: all 0.2s ease-in-out;
    }
  }

  &__label {
    margin-left: 10px;
    width: calc(100% - $toggle-width);
  }

  &--has-value {
    .Checkbox__input-wrapper {
      background: var(--toggle-checked);

      &::after {
        left: calc(100% - $toggle-dot-width - $toggle-dot-offset);
      }
    }
  }

  input[type='checkbox'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    top: 0;
    left: 0;
  }
}
