@import '../../skin/theme/colors.css';

.Footer {
  background-color: var(--primary-color);
  color: var(--white);

  .topSection {
    background-color: var(--secondary-color);

    .container {
      padding: 10px 15vw;
      min-height: 50px;
      font-size: 9px;
      font-weight: 600;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .LanguageSelector {
        bottom: calc(50% - 13.5px);
      }

      nav {
        line-height: 30px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;

        .navigation {
          white-space: nowrap;
          font-size: 12px;
          margin: 0 20px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  /* Content / Bottom section */
  .content-container {
    display: block;
    padding: 0 15vw;
    width: 100%;
    text-align: center;
    max-width: 100%;

    .icons {
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      justify-content: center;
    }

    .icons img {
      filter: saturate(0);
      height: 20px;
      opacity: 0.5;
      margin: 0 10px;
      margin-bottom: 20px;

      &:hover {
        filter: saturate(1);
        opacity: 1;
      }
    }

    .section {
      padding-bottom: 40px;

      h3 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 0;
        a {
          text-decoration: none;

          &:hover {
            color: var(--primary-color);
          }
        }
      }

      &.one {
        .footer-logo {
          img {
            margin-bottom: 24px;
            height: 32px;
          }
        }

        .trademark {
          margin-bottom: 24px;
          font-size: 12px;
        }

        .licence,
        .social {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          filter: saturate(0%);
          &:hover {
            filter: saturate(100%);
          }
        }

        .social {
          filter: initial;
          flex-wrap: wrap;
          gap: 1em;
        }

        .rights-reserved-content {
          font-size: 12px;
        }

        .licence img,
        .social img {
          height: 44px;
          margin-right: 10px;
          display: block;
        }

        .social img {
          height: 30px;
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Footer {
    .topSection {
      .container {
        padding: 10px 15px;
      }
    }

    .content-container {
      padding: 0 15px;
    }
  }
}
