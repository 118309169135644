.StickyFooter-mobile {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.3);

  .stickyfooter-nav-items {
    a {
      text-decoration: none;
    }
  }

  .hamburger-icon,
  .exit-sidebar-icon {
    img {
      width: 18px;
    }
  }
}
