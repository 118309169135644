.SignUpSga {
  text-align: left;

  .promo {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    .tg-input {
      flex: 1;
      margin-bottom: 0.5em;
    }

    .promo-close {
      flex-basis: 40px;
      height: 40px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
