.layout-item.promo {
  padding: 0 3px 0 7px;
}

.bonus-item {
  background: var(--primary-color);
  margin-top: 20px;
  border-radius: 10px;

  .bonus-content-wrapper {
    padding: 0 20px 20px 20px;
  }
}

.bonus-footer {
  position: relative;
  max-width: 460px;
  margin: 30px auto;
  height: auto;
  background: none;
  flex-direction: column-reverse;
  padding: 0;
  width: 100%;
  color: var(--primary-text-color);
  box-shadow: var(--button-shadow);
  border-radius: 2px;

  .bonus-background {
    height: calc(100% - 45px);
    width: calc(100% - 20px);
    left: 10px;
    top: 35px;
    position: absolute;
    border-radius: 10px;
  }

  .content-wrapper {
    display: block;

    .content {
      background: var(--white);
      border-radius: var(--border-radius);
      padding: 10px;
      box-shadow: var(--shadow);
    }

    .icon-promotions {
      display: none;
    }

    .right {
      position: absolute;
      right: 10px;
      top: 10px;
      text-decoration: underline;

      span {
        margin: 0;
      }
    }

    .selected-bonus {
      > span {
        font-size: 14px !important;
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .title {
        min-height: 80px;
        font-size: 17px;
        color: var(--white);
        padding: 15px;
        margin-top: 25px;
        display: flex;
        align-items: center;
        z-index: 10;
        max-width: 55%;
        text-shadow: 1px 1px var(--primary-text-color);
      }
    }
  }

  @media (max-width: 992px) {
    bottom: unset;
  }
}
