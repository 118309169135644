.section.support {
  button {
    position: relative;

    &::after {
      content: '';
      background: url('../images/support-icon.svg') no-repeat right;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 20px;
    }
  }
}
