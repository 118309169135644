@import './colors.css';

.ModernGameView {
  &--expanded {
    .ModernGameView__mini-sidebar__minimize-button {
      i::before {
        content: '\e943';
      }
    }
  }

  &__mini-sidebar {
    .icon {
      color: var(--white);
    }
    background: url('../images/sidebar-pattern.png') var(--secondary-color) !important;
    &__minimize-button {
      background-color: var(--primary-color) !important;
      box-shadow: 0 0 0 3px #fff, -2px 2px 0 2px #003e5d, 0 0 0 5px #003e5d;
    }
    &__close-button {
      background-color: var(--primary-color) !important;
      box-shadow: 0 0 0 3px #fff, -2px 2px 0 2px #003e5d, 0 0 0 5px #003e5d;
    }
  }

  .GameDetails {
    background: url('../images/sidebar-pattern.png') var(--secondary-color) !important;
    &__content {
      &__logo {
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 80%;
        }
      }
    }
    &__back-arrow {
      width: 34px;
      background: #00abc6;
      padding: 0 !important;
      border-radius: 20%;
      box-shadow: 0 0 0 3px #fff, -4px 4px 0 4px #003e5d, 0 0 0 5px #003e5d;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 26px !important;
        padding-left: 5px;
      }
      span {
        display: none;
      }
    }
    &__category-menu {
      &__categories {
        &__item {
          &--active {
            border-bottom: none;
            color: #003e5d;
          }
          text-decoration: none;
        }
      }
      &__fade {
        &--right {
          box-shadow: inset -25px 0 10px -4px var(--secondary-color);
        }
        &--left {
          box-shadow: inset 25px 0 10px -4px var(--secondary-color);
        }
      }
    }
    &__search {
      color: var(--primary-color);
      flex-direction: row-reverse;
      i {
        color: var(--primary-color);
        position: absolute;
        left: 30px;
        margin-left: unset;
        font-size: 20px;
      }
      input {
        color: var(--primary-color);
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
        text-align: center;

        &::placeholder {
          color: var(--primary-color);
        }
      }
    }
  }
}
