.tg-input__status-text,
  .tg-input__helper-text,
  .tg-select__status-text,
  .tg-select__helper-text,
  .tg-password-input__status-text,
  .tg-password-input__helper-text,
  .tg-phonenumber-input__status-text,
  .tg-phonenumber-input__helper-text,
  .tg-birthdate-input__status-text,
  .tg-birthdate-input__helper-text,
  .tg-checkbox__status-text,
  .tg-checkbox__helper-text,
  .tg-autocomplete-input__status-text,
  .tg-autocomplete-input__helper-text {
    font-size: 0.85em;
  }

.tg-input__status-text, .tg-select__status-text, .tg-password-input__status-text, .tg-phonenumber-input__status-text, .tg-birthdate-input__status-text, .tg-checkbox__status-text, .tg-autocomplete-input__status-text {
    min-height: 1.5em;
    line-height: 1.5em;
  }

.tg-input--status-failure .tg-input__status-text, .tg-select--status-failure .tg-select__status-text, .tg-password-input--status-failure .tg-password-input__status-text, .tg-phonenumber-input--status-failure .tg-phonenumber-input__status-text, .tg-birthdate-input--status-failure .tg-birthdate-input__status-text, .tg-checkbox--status-failure .tg-checkbox__status-text, .tg-autocomplete-input--status-failure .tg-autocomplete-input__status-text {
    color: #d63e3e;
  }

.tg-password-input .tg-password-input__toggle.icon {
    pointer-events: all;
  }

.tg-phonenumber-input {
  display: flex;
  flex-flow: row wrap;
}

.tg-phonenumber-input .Select {
    flex-basis: 120px;
    margin-right: 0.5em;
  }

.tg-phonenumber-input .Input {
    flex: 1 1 1%;
  }

.tg-phonenumber-input__status-text,
  .tg-phonenumber-input__helper-text {
    width: 100%;
  }

.tg-autocomplete-input {
  position: relative;
}

.tg-autocomplete-input__suggestions {
    position: absolute;
    top: 48.75px;
    background: #eee;
    width: 100%;
    color: #000;
    z-index: 1;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
  }

.tg-autocomplete-input__suggestions__suggestion {
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #000;
    }

.tg-autocomplete-input__suggestions__suggestion--selected {
        background: #dcdcdc;
      }

.tg-birthdate-input {
  display: flex;
  flex-flow: row wrap;
}

.tg-birthdate-input__label {
    width: 100%;
  }

.tg-birthdate-input .Select {
    flex: 1 1 1%;
    margin-left: 0.5em;
  }

.tg-birthdate-input .Select:first-of-type {
    margin-left: 0;
  }

.tg-birthdate-input__status-text,
  .tg-birthdate-input__helper-text {
    width: 100%;
  }

.Input,
.Select {
  position: relative;
  overflow: hidden;
}

.Input--type-hidden, .Select--type-hidden {
    display: none;
  }

.Input__input,
  .Input__select,
  .Select__input,
  .Select__select {
    display: block;
    -webkit-appearance: none;
            appearance: none;
    border: none;
    outline: none;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc(10px + var(--leading-lane-width));
    padding-right: calc(10px + var(--trailing-lane-width));
    font-size: 14px;
    line-height: 1.8em;
    width: 100%;
  }

.Input__input[type='number']::-webkit-inner-spin-button,
    .Input__input[type='number']::-webkit-outer-spin-button,
    .Input__select[type='number']::-webkit-inner-spin-button,
    .Input__select[type='number']::-webkit-outer-spin-button,
    .Select__input[type='number']::-webkit-inner-spin-button,
    .Select__input[type='number']::-webkit-outer-spin-button,
    .Select__select[type='number']::-webkit-inner-spin-button,
    .Select__select[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

.Input__input[type='number'], .Input__select[type='number'], .Select__input[type='number'], .Select__select[type='number'] {
      -moz-appearance: textfield;
    }

.Input--has-label .Input__input,
    .Input--has-label .Select__select,
    .Select--has-label .Input__input,
    .Select--has-label .Select__select {
      padding-top: 17px;
      padding-bottom: 3px;
    }

.Input--has-label .Input__input::-webkit-input-placeholder, .Input--has-label .Select__select::-webkit-input-placeholder, .Select--has-label .Input__input::-webkit-input-placeholder, .Select--has-label .Select__select::-webkit-input-placeholder {
        font-size: 1em;
        -webkit-transition: color 0.17s, font-size 0.17s;
        transition: color 0.17s, font-size 0.17s;
      }

.Input--has-label .Input__input::placeholder, .Input--has-label .Select__select::placeholder, .Select--has-label .Input__input::placeholder, .Select--has-label .Select__select::placeholder {
        font-size: 1em;
        transition: color 0.17s, font-size 0.17s;
      }

.Input--has-label .Input__input option, .Input--has-label .Select__select option, .Select--has-label .Input__input option, .Select--has-label .Select__select option {
        color: #000000;
      }

.Input--has-label:not(.Input--has-focus) .Input__input::-webkit-input-placeholder, .Select--has-label:not(.Select--has-focus) .Input__input::-webkit-input-placeholder {
      font-size: 0.9em;
      color: transparent;
    }

.Input--has-label:not(.Input--has-focus) .Input__input::placeholder, .Select--has-label:not(.Select--has-focus) .Input__input::placeholder {
      font-size: 0.9em;
      color: transparent;
    }

.Input__label, .Select__label {
    position: absolute;
    top: 50%;
    left: calc(10px + var(--leading-lane-width));
    pointer-events: none;
    font-size: 1em;
    white-space: nowrap;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: left;
            transform-origin: left;
    transition: -webkit-transform 0.15s;
    transition: transform 0.15s;
    transition: transform 0.15s, -webkit-transform 0.15s;
  }

.Input--has-focus .Input__label, .Input--has-value .Input__label, .Select--has-focus .Input__label, .Select--has-value .Input__label {
      -webkit-transform: translateY(-106%) scale(0.75);
              transform: translateY(-106%) scale(0.75);
    }

.Input--has-value .Select__label, .Select--has-value .Select__label {
      -webkit-transform: translateY(-106%) scale(0.75);
              transform: translateY(-106%) scale(0.75);
    }

.Input--has-label.Input:not(.Input--has-value) .Select__select, .Select--has-label.Select:not(.Select--has-value) .Select__select {
      color: transparent;
    }

.Input--status-failure, .Select--status-failure {
    caret-color: #d63e3e;
  }

.Input--status-failure .Input__label, .Input--status-failure .Select__label, .Select--status-failure .Input__label, .Select--status-failure .Select__label {
        color: #d63e3e;
      }

.Input--status-failure .Input__status-line, .Input--status-failure .Select__status-line, .Select--status-failure .Input__status-line, .Select--status-failure .Select__status-line {
        background-color: #d63e3e;
      }

.Input--status-success .Input__label, .Input--status-success .Select__label, .Select--status-success .Input__label, .Select--status-success .Select__label {
        color: #3edb94;
      }

.Input--status-success .Input__status-line, .Input--status-success .Select__status-line, .Select--status-success .Input__status-line, .Select--status-success .Select__status-line {
        background-color: #3edb94;
      }

.Input__leading-lane,
  .Input__trailing-lane,
  .Select__leading-lane,
  .Select__trailing-lane {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: flex;
    pointer-events: none;
    color: #00abc6;
  }

.Input__leading-lane .icon,
    .Input__leading-lane svg,
    .Input__trailing-lane .icon,
    .Input__trailing-lane svg,
    .Select__leading-lane .icon,
    .Select__leading-lane svg,
    .Select__trailing-lane .icon,
    .Select__trailing-lane svg {
      font-size: 20px;
      width: 20px;
      height: 20px;
      text-align: center;
      pointer-events: none;
    }

.Input__leading-lane:last-child, .Input__trailing-lane:last-child, .Select__leading-lane:last-child, .Select__trailing-lane:last-child {
      margin-left: 0;
      margin-right: 0;
    }

.Input__leading-lane, .Select__leading-lane {
    left: 0px;
  }

.Input__leading-lane > *, .Select__leading-lane > * {
      margin-left: 10px;
    }

.Input__trailing-lane, .Select__trailing-lane {
    right: 0px;
  }

.Input__trailing-lane > *, .Select__trailing-lane > * {
      margin-right: 10px;
    }

.Input__status-line, .Select__status-line {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #00abc6;
  }

.Input__status-line--active, .Select__status-line--active {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }

.Input .SuccessIcon, .Select .SuccessIcon {
    color: #3edb94;
  }

.Input .FailureIcon, .Select .FailureIcon {
    color: #d63e3e;
  }

input[type='radio'] {
  -webkit-appearance: none;
  min-width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #484848;
  margin-right: 12px;
}

input[type='radio']:checked {
  background-color: #151515;
  border: 5px solid #00abc6;
}

@media (max-width: 992px) {
    .Input__input,
    .Input__select,
    .Select__input,
    .Select__select {
      font-size: 16px !important;
    }
}
