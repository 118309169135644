.SignInContainer {
  .sign-in-wrapper {
    form {
      & > * {
        margin: 35px 0 0;
      }
    }

    .button-wrapper {
      margin: 35px 0 0;
    }
  }
}
