.button {
  text-transform: uppercase;
}

.button.primary,
.primary-button {
  background: var(--primary-btn-background);
  box-shadow: var(--button-shadow);
  border-radius: 3px;
  color: var(--white);

  &:hover {
    opacity: 0.8;
  }
}

.button.secondary {
  box-shadow: var(--button-shadow);
  border-radius: 3px;

  &:hover {
    opacity: 0.8;
  }
}

.button.icon {
  border-radius: 3px;
  background: var(--primary-btn-background);
  box-shadow: var(--button-shadow);

  color: var(--white);
}

.button.secondary,
.Promotion .button.primary,
.Tournament .button.primary,
.payment-wrapper
  .cashier-accordion
  .payment-method-item-content
  button.button.secondary,
.limit-container .button.primary,
.responsiblegaming-signup .custom-amount .button.custom-amount-decrement,
.responsiblegaming-signup .custom-amount .button.custom-amount-increment,
.limit-container .custom-amount div.custom-amount-decrement,
.limit-container .custom-amount div.custom-amount-increment {
  color: var(--white);
  background: var(--secondary-btn-background);
  box-shadow: var(--button-shadow);

  &:hover {
    background: var(--secondary-btn-background-hover);
    color: var(--white);
  }

  &:disabled {
    opacity: 0.75;
    color: var(--primary-text-color);
  }
}

.limit-container .timespan-selector .button.primary.selected {
  background: var(--secondary-btn-background-hover);
  color: var(--white);
}

.Header__actions {
  .button.primary {
    position: relative;

    span {
      position: relative;
      z-index: 10;
    }

    &::before {
      content: '';
      position: absolute;
      left: -60px;
      top: 0;
      background: url('../images/fast-lines.svg');
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

.InlinePnp__button {
  .button.primary {
    font-size: 1.5em;

    &::before {
      left: -70px;
    }
  }
}

.button.tertiary {
  background: var(--gray);
  color: var(--primary-text-color);
  box-shadow: var(--button-shadow);
  border-radius: 3px;
}

.button {
  svg {
    circle {
      stroke: var(--white);
    }
  }
}

.request-reset-password-button {
  margin-top: 10px;
}

.OffCanvas .password + div button {
  margin-top: 20px;
}
